<template>
  <b-card no-body title="Users">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="initalLoadTimelineForUser">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table
        responsive
        :fields="fields"
        :items="getTimelineForUser"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        sort-icon-left
        no-local-sorting
      >
        <template #cell(user)="data">
          {{ data.item.fullName }} <br />
          <small>({{ data.item.username }})</small>
        </template>
        <template #cell(created)="data">
          {{ data.value ? showDateInFormat(data.value) : "Never" }}
        </template>
        <template #cell(deviceInformation)="data">
          <small><b>Version: </b>{{ data.item.deviceVersion }}</small> <br />
          <small> <b>Screen: </b>{{ data.item.deviceScreen }}</small> <br />
          <small><b>Type: </b>{{ data.item.deviceType }}</small> <br />
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { getTimelineForUser, filterBy, setPerPage, getLoading, getTotalRows, getPerPage, onLoadTimelineForUser, sortBy, sortOrder } from "@/views/users/timelineRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch, reactive } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "UserTimeline",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  emits: ["update:visible"],
  props: {
    user: null
  },
  setup(props, { root, emit }) {
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const currentPage = ref(1);
    const isSortDesc = ref(false);
    const fields = [
      { key: "created", sortable: true },
      "user",
      { key: "event", sortable: true },
      { key: "requestPath", label: "Url Path", sortable: true },
      { key: "duration", sortable: true }
      //"deviceInformation",
      //"auditId",
      //"ipAddress",
      //"browserAgent"
    ];

    const userObj = reactive({
      value: null
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadTimelineForUser = async () => {
      const usuid = props.user && props.user.userUid ? props.user.userUid : undefined;
      await onLoadTimelineForUser({ prevPage: false, nextPage: false, reset: true, userUid: usuid });
    };

    /*onMounted(() => {
      filterBy.value = "";
      initalLoadTimelineForUser();
    });*/

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadTimelineForUser();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadTimelineForUser({ prevPage: false, nextPage: false, reset: true, userUid: props.user.userUid });
        return;
      }
      if (currentPage.value > val) {
        await onLoadTimelineForUser({ prevPage: true, nextPage: false, userUid: props.user.userUid });
        currentPage.value = val;
        return;
      }
      await onLoadTimelineForUser({ prevPage: false, nextPage: true, userUid: props.user.userUid });
      currentPage.value = val;
    };

    watch(filterBy, (newValue, oldValue) => {
      initalLoadTimelineForUser();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadTimelineForUser();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadTimelineForUser();
    });

    onMounted(() => {
      console.log("GETTING USER");
      console.log(props.user);
      userObj.value = props.user;
      filterBy.value = "";
      if (props.user && props.user.userUid) {
        initalLoadTimelineForUser();
      }
    });

    return {
      getTimelineForUser,
      filterBy,
      setPerPage,
      getPerPage,
      getLoading,
      onLoadTimelineForUser,
      getTotalRows,
      fields,
      currentPage,
      pageOptions,
      showDateInFormat,
      onPageChanged,
      perPageChanged,
      userObj,
      initalLoadTimelineForUser,
      sortBy,
      sortOrder,
      isSortDesc
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
