import { ref, computed } from "@vue/composition-api";

import { requestListTimelineForUser } from "@/service/UserService";

export const timelineList = ref([]);
export const filterBy = ref(null);
export const sortBy = ref("created");
export const sortOrder = ref("desc");

const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getTimelineForUser = computed(() => timelineList.value);

export const clearUserTimeline = () => {
  timelineList.value = [];
};

export const onLoadTimelineForUser = async ({ prevPage, nextPage, reset, userUid }) => {
  if (!userUid) {
    timelineList.value.splice(0, timelineList.value.length);

    return [];
  }
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }

  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: sortBy.value && sortBy.value.length > 0 ? sortBy.value : null,
    sortOrder: sortOrder.value,
    userUid: userUid,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { timeline, pagination }
    } = await requestListTimelineForUser(reqData);
    if (timeline) {
      timelineList.value.splice(0, timelineList.value.length);
      timelineList.value.push(...timeline);
    } else {
      timelineList.value.splice(0, timelineList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};
